import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteSearchHistory as deleteSearchHistoryApi,
  getSearchHistories,
  SEARCH_HISTORY_PAGE_SIZE,
} from "helpers/api";
import { useMemo } from "react";

export const SEARCH_HISTORIES_QUERY_KEY = ["get_search_histories"];

function useSearchHistory() {
  const queryClient = useQueryClient();

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    SEARCH_HISTORIES_QUERY_KEY,
    async ({ pageParam = 0 }) => {
      const data = await getSearchHistories(
        SEARCH_HISTORY_PAGE_SIZE,
        pageParam
      );
      return data?.search_histories;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.length === SEARCH_HISTORY_PAGE_SIZE)
          return allPages.length * SEARCH_HISTORY_PAGE_SIZE;
        return undefined;
      },
    }
  );

  // Concatenate all threads into a single array
  const searches = useMemo(
    () => data?.pages.flatMap((page) => page),
    [data?.pages]
  );

  const deleteSearchHistory = async (id: string) => {
    await deleteSearchHistoryApi(id);
    // Refetch the search histories to remove the deleted item from the list
    queryClient.invalidateQueries({
      queryKey: SEARCH_HISTORIES_QUERY_KEY,
      exact: true,
    });
  };

  return {
    deleteSearchHistory,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetchingNextPage,
    isLoading,
    searches,
  };
}

export default useSearchHistory;
