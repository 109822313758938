import { useInfiniteQuery } from "@tanstack/react-query";
import { getThreads, THREADS_PAGE_SIZE } from "helpers/api";
import { useMemo } from "react";

export const THREADS_QUERY_KEY = ["get_threads"];

function useThreads(enabled?: boolean) {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    THREADS_QUERY_KEY,
    async ({ pageParam = 0 }) => {
      const data = await getThreads(THREADS_PAGE_SIZE, pageParam);
      return data?.threads;
    },
    {
      enabled: enabled,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.length === THREADS_PAGE_SIZE)
          return allPages.length * THREADS_PAGE_SIZE;
        return undefined;
      },
    }
  );

  // Concatenate all threads into a single array
  const threads = useMemo(
    () => data?.pages.flatMap((page) => page),
    [data?.pages]
  );

  return {
    fetchNextPage,
    hasNextPage,
    isError,
    isFetchingNextPage,
    isLoading: isLoading && enabled,
    threads,
  };
}

export default useThreads;
