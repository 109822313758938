import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useFilters from "features/FilterDrawer/hooks/useFilters";
import {
  convertDurationToDays,
  getQueryFromFilters,
} from "features/FilterDrawer/utils/queryParams/filterQueryParams";
import {
  parseThreadInteractionResponse,
  parseThreadResponse,
  Thread,
  ThreadInteraction,
} from "features/Thread/types";
import {
  addInteractionToThread,
  deleteThread,
  deleteThreadInteraction,
  getThread,
  ThreadInteractionOverrides,
  updateThread as updateThreadAPI,
} from "helpers/api";
import { FilterParams } from "helpers/pageUrl";
import useProToggle from "hooks/useProToggle";
import { useAppSelector } from "hooks/useStore";
import { useCallback } from "react";

/**
 * Generates the query key for thread caching. This is shared between hooks, for instance to allow useNewThread to pre-populate a thread in the query cache.
 */
export function getThreadQueryKey(threadId: number) {
  return ["thread", threadId];
}

export function getAddThreadInteractionQueryKey(threadId: number) {
  return ["add-thread-interaction", threadId];
}

/**
 * `useThread` is a custom React hook for managing the state and interactions of a thread.
 * It uses `react-query` for fetching, caching, and updating thread data, offering:
 *
 * - **Fetching**: Loads thread data by `threadId`.
 * - **Adding Interactions**: Appends new interactions and updates the cache.
 * - **Updating Interactions**: Modifies specific interactions in the cache.
 */
export function useThread(threadId: number) {
  const queryClient = useQueryClient();
  const { isProToggleOn } = useProToggle();
  const { appliedFilters, removeAllFilters } = useFilters();
  const { lang } = useAppSelector((state) => state.user.settings);

  const THREAD_QUERY_KEY = getThreadQueryKey(threadId);

  // Fetch thread by ID
  const { data, isLoading, error } = useQuery<Thread, Error>(
    THREAD_QUERY_KEY,
    async () => {
      const response = await getThread(threadId);
      return parseThreadResponse(response);
    },
    {
      enabled: !!threadId,
    }
  );

  const updateThreadMutation = useMutation(
    async ({ isShared, title }: { isShared?: boolean; title?: string }) => {
      return await updateThreadAPI(threadId, {
        is_shared: isShared,
        title,
      });
    },
    {
      onMutate: async (variables) => {
        await queryClient.cancelQueries(THREAD_QUERY_KEY);

        const previousData = queryClient.getQueryData<Thread>(THREAD_QUERY_KEY);

        queryClient.setQueryData<Thread>(THREAD_QUERY_KEY, (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            title: variables.title ?? oldData.title,
            is_shared: variables.isShared ?? oldData.is_shared,
          };
        });

        return { previousData };
      },
      onError: (_error, _variables, context: any) => {
        if (context?.previousData) {
          queryClient.setQueryData(THREAD_QUERY_KEY, context.previousData);
        }
      },
      onSuccess: (updatedThread) => {
        // Re-apply values from server in case they differ
        queryClient.setQueryData<Thread>(THREAD_QUERY_KEY, (oldData) => {
          if (!oldData) {
            queryClient.invalidateQueries({ queryKey: THREAD_QUERY_KEY });
            return undefined;
          }

          return {
            ...oldData,
            title: updatedThread.title ?? oldData.title,
            is_shared: updatedThread.is_shared ?? oldData.is_shared,
          };
        });
      },
    }
  );

  const updateThread = useCallback(
    async ({ title, isShared }: { title?: string; isShared?: boolean }) => {
      if (threadId) {
        await updateThreadMutation.mutateAsync({
          title,
          isShared,
        });
      }
    },
    [threadId, updateThreadMutation]
  );

  const addInteractionMutation = useMutation(
    async ({
      threadId,
      userMessage,
      filters,
      overrides,
    }: {
      threadId: number;
      userMessage: string;
      filters: FilterParams;
      overrides?: ThreadInteractionOverrides;
    }) => {
      const response = await addInteractionToThread({
        threadId,
        userMessage,
        filters,
        overrides,
        isProEnabled: isProToggleOn ?? false,
        lang,
      });

      return parseThreadInteractionResponse(response);
    },
    {
      mutationKey: getAddThreadInteractionQueryKey(threadId),
      onMutate: async ({ userMessage }) => {
        await queryClient.cancelQueries(THREAD_QUERY_KEY);

        // Create a "loading" interaction (client-side only)
        const optimisticInteraction: ThreadInteraction = {
          id: Date.now(), // Temporary client-only ID
          user_message: userMessage,
          is_pro_enabled: false,
          created_at: new Date().toISOString(),
          isLoading: true,
        };

        // Optimistically update the cache with the loading interaction
        queryClient.setQueryData<Thread>(THREAD_QUERY_KEY, (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            interactions: [...oldData.interactions, optimisticInteraction],
          };
        });

        return { optimisticInteraction };
      },
      // Note: Using "any" to solve type inference issue, can be removed after updating ts:
      // https://tkdodo.eu/blog/react-query-and-type-script#optimistic-updates
      onSuccess: (newInteraction, _, context: any) => {
        queryClient.setQueryData<Thread>(THREAD_QUERY_KEY, (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            interactions: oldData.interactions.map((interaction) =>
              interaction.id === context?.optimisticInteraction.id
                ? newInteraction // Replace loading state with real response
                : interaction
            ),
          };
        });
      },
      onError: (_, __, context) => {
        // Remove the loading interaction if the request fails
        queryClient.setQueryData<Thread>(THREAD_QUERY_KEY, (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            interactions: oldData.interactions.filter(
              (i) => i.id !== context?.optimisticInteraction.id
            ),
          };
        });
      },
    }
  );

  // Dispatch a new search to a thread, creating a new ThreadInteraction and saving it to the thread.
  const addInteractionToThreadById = useCallback(
    async (
      userMessage: string,
      overrides?: ThreadInteractionOverrides,
      disableFilters = false
    ) => {
      if (threadId) {
        // API only accepts "day" units for duration, so convert it before passing.
        const filters = disableFilters
          ? {}
          : convertDurationToDays(appliedFilters);

        if (disableFilters) {
          removeAllFilters();
        }

        await addInteractionMutation.mutateAsync({
          threadId,
          userMessage,
          filters: getQueryFromFilters(filters),
          overrides,
        });
      }
    },
    [threadId, addInteractionMutation]
  );

  // Update an existing query in the thread, such as to save the analysis block.
  const updateThreadInteractionCacheById = useCallback(
    (threadInteractionId: number, updates: Partial<ThreadInteraction>) => {
      queryClient.setQueryData<Thread>(THREAD_QUERY_KEY, (oldData) => {
        if (!oldData) return oldData;

        return {
          ...oldData,
          interactions: oldData.interactions.map((interaction) =>
            interaction.id === threadInteractionId
              ? { ...interaction, ...updates }
              : interaction
          ),
        };
      });
    },
    [queryClient, threadId]
  );

  const deleteInteractionMutation = useMutation(
    async (threadInteractionId: number) => {
      return await deleteThreadInteraction({
        threadId,
        threadInteractionId,
      });
    },
    {
      onMutate: async (threadInteractionId): Promise<Thread | undefined> => {
        await queryClient.cancelQueries(THREAD_QUERY_KEY);

        const context = queryClient.getQueryData<Thread>(THREAD_QUERY_KEY);

        queryClient.setQueryData<Thread>(THREAD_QUERY_KEY, (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            interactions: oldData.interactions.filter(
              (interaction) => interaction.id !== threadInteractionId
            ),
          };
        });

        return context;
      },
      onError: (_, __, context: Thread | undefined) => {
        if (context) {
          queryClient.setQueryData(THREAD_QUERY_KEY, context);
        }
      },
    }
  );

  // Delete an interaction from the thread.
  const deleteThreadInteractionById = useCallback(
    async (threadInteractionId: number) => {
      if (threadId) {
        await deleteInteractionMutation.mutateAsync(threadInteractionId);
      }
    },
    [threadId, deleteInteractionMutation]
  );

  const deleteThreadMutation = useMutation(
    async (threadId: number) => {
      return await deleteThread({ threadId });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(THREAD_QUERY_KEY);
      },
    }
  );

  // Delete the thread.
  const deleteThreadById = useCallback(
    async (threadId: number) => {
      return deleteThreadMutation.mutateAsync(threadId);
    },
    [threadId, deleteThreadMutation]
  );

  const isNewInteractionLoading =
    data?.interactions.some((interaction) => interaction.isLoading) ?? false;

  return {
    thread: data,
    error,
    isLoading, // General loading state for fetching the thread
    updateThread,
    isNewInteractionLoading,
    addInteractionToThreadById,
    deleteThreadInteractionById,
    updateThreadInteractionCacheById,
    deleteThreadById,
  };
}
