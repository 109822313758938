import { CoButton } from "components/CoButton";
import ProToggle from "components/ProToggle";
import { usePremiumFeatureModal } from "components/Subscription";
import { FilterButton } from "features/FilterDrawer";
import useCredits from "hooks/useCredits";
import useLabels from "hooks/useLabels";
import useProToggle from "hooks/useProToggle";
import { useEffect, useRef, useState } from "react";
import TextArea from "react-expanding-textarea";
import { CreditedFeature } from "store/slices/subscription";
import useNewThread from "../hooks/useNewThread";

type NewThreadInputProps = {
  onClose: () => void;
};

/**
 * @component NewThreadInput
 * @description The text input that renders in the center of the New Thread Modal after selecting "new thread" from the sidebar.
 */
function NewThreadInput({ onClose }: NewThreadInputProps) {
  const [labels] = useLabels("features.new-thread-button");
  const { isPremium, isOutOfProAnalysisCredits } = useCredits();
  const { isProToggleOn } = useProToggle();
  const { openPremiumFeatureModal } = usePremiumFeatureModal();

  const { createNewThread } = useNewThread();

  const [newMessage, setNewMessage] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    textareaRef.current?.focus();
  }, []);

  const handleMessageInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNewMessage(e.target.value);
  };

  const handleSubmit = async () => {
    if (newMessage.trim()) {
      if (isOutOfProAnalysisCredits && isProToggleOn && !isPremium) {
        openPremiumFeatureModal(CreditedFeature.PRO_ANALYSIS, true);
      } else {
        createNewThread(newMessage.trim());
        setNewMessage("");
        onClose();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className="flex flex-col w-full h-full px-4 pt-4 pb-2 mx-auto space-y-2 bg-white rounded-xl max-w-page outline outline-2 outline-offset-2 outline-accent-subtle">
      <TextArea
        name="newMessage"
        value={newMessage}
        placeholder={labels["ask-the-research"]}
        onChange={handleMessageInputChange}
        onKeyDown={handleKeyDown}
        className="flex-grow w-full overflow-hidden outline-none resize-none text-fg-muted"
        ref={textareaRef}
      />
      <div className="flex">
        <div className="flex items-center flex-grow space-x-2">
          <ProToggle />
          <FilterButton />
        </div>
        <CoButton
          disabled={newMessage.length === 0}
          onClick={handleSubmit}
          icon="icon-arrow-right"
          size="sm"
        />
      </div>
    </div>
  );
}

export default NewThreadInput;
