import { useAuth } from "@clerk/nextjs";
import NewThreadModal from "features/NewThreadButton/components/NewThreadModal";
import { useSidebar } from "hooks/useSidebar";
import { useAppSelector } from "hooks/useStore";
import { useEffect, useState } from "react";
import SidebarCollapsedContent from "./components/SidebarCollapsedContent";
import SidebarContent from "./components/SidebarContent";

function Sidebar() {
  const { isSignedIn, isLoaded } = useAuth();
  const { toggleSidebar, isSidebarOpen } = useSidebar();
  const is898WideScreen = useAppSelector(
    (state) => state.setting.is898WideScreen
  );
  const [sidebarDefaultSet, setSidebarDefaultSet] = useState(false);

  // Open sidebar by default when not signed in on desktop
  useEffect(() => {
    if (isLoaded && !isSignedIn && !sidebarDefaultSet) {
      if (!is898WideScreen) {
        toggleSidebar();
      }
      setSidebarDefaultSet(true);
    }
  }, [isSignedIn, isLoaded, is898WideScreen, sidebarDefaultSet, toggleSidebar]);

  const expandSidebar = () => {
    toggleSidebar();
  };

  const showCollapsedSidebar = !is898WideScreen;

  return (
    <div
      className="z-40 h-full border-r border-base"
      data-testid="sidebar-container"
    >
      {isSidebarOpen ? (
        <SidebarContent />
      ) : showCollapsedSidebar ? (
        <SidebarCollapsedContent onExpand={expandSidebar} />
      ) : null}
      <NewThreadModal />
    </div>
  );
}

export default Sidebar;
