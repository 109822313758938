import classNames from "classnames";
import { ButtonVariant, CoButton } from "components/CoButton";
import { FeatureFlag } from "enums/feature-flag";
import { useSaveBookmarkModal } from "features/Bookmarks";
import {
  getSearchPathFromSearchHistory,
  groupHistoriesByDate,
} from "features/History/utils/histories";
import useThreads from "features/Sidebar/hooks/useThreads";
import { MixpanelActionSource } from "helpers/services/mixpanel/events";
import useAnalytics from "hooks/useAnalytics";
import { useIncognitoMode } from "hooks/useIncognitoMode";
import useIsFeatureEnabled from "hooks/useIsFeatureEnabled";
import useLabels from "hooks/useLabels";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import SidebarContentLoading from "../Sidebar/components/SidebarContentLoading";
import HistoryEmpty from "./components/HistoryEmpty";
import HistoryGroup from "./components/HistoryGroup";
import useSearchHistory from "./hooks/useSearchHistory";
import { HistoryItem, HistoryType } from "./types";

type HistoryProps = {
  onNavigate: () => void;
};

/**
 * @component History
 * @description An infinite scrolling list of a user's search history. Each item can be clicked to navigate to that search, and has a menu to delete or bookmark the search.
 *
 * 3/30/25: As of launching threads, this component now fetches both the user's list of threads and their "search histories", the feature that we used to save the user's history before threads. Both types of history are identical in the sidebar. Clicking a search history navigates to the /results/ page, which automatically redirects to a new thread. So, in order to prevent duplication, we delete a search history item whenever it is clicked.
 */
function History({ onNavigate }: HistoryProps) {
  const router = useRouter();
  const [searchHistoryLabels] = useLabels("screens.sidebar.history");
  const { setAnalyticActionSource } = useAnalytics();
  const { isIncognitoModeActive } = useIncognitoMode();

  const { openSaveBookmarkModalForSearch, openSaveBookmarkModalForThread } =
    useSaveBookmarkModal();

  const {
    fetchNextPage: fetchNextSearchHistoryPage,
    hasNextPage: hasNextSearchHistoryPage,
    isError: isSearchHistoryError,
    isFetchingNextPage: isFetchingNextSearchHistoryPage,
    isLoading: isSearchHistoryLoading,
    searches,
    deleteSearchHistory,
  } = useSearchHistory();

  const isThreadsEnabled = useIsFeatureEnabled(FeatureFlag.THREADS);

  const {
    fetchNextPage: fetchNextThreadsPage,
    hasNextPage: hasNextThreadsPage,
    isError: isThreadsError,
    isFetchingNextPage: isFetchingNextThreadsPage,
    isLoading: isThreadsLoading,
    threads,
  } = useThreads(isThreadsEnabled);

  const isError = isSearchHistoryError || isThreadsError;
  const isLoading = isSearchHistoryLoading || isThreadsLoading;
  const hasNextPage = hasNextSearchHistoryPage || hasNextThreadsPage;
  const isFetchingNextPage =
    isFetchingNextSearchHistoryPage || isFetchingNextThreadsPage;

  const fetchNextPage = () => {
    if (hasNextSearchHistoryPage) fetchNextSearchHistoryPage();
    if (hasNextThreadsPage) fetchNextThreadsPage();
  };

  const groupedData = useMemo(
    () => groupHistoriesByDate(searches ?? [], threads ?? [], router.query),
    [searches, threads, router.query]
  );

  const [infiniteRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage: !!hasNextPage,
    onLoadMore: fetchNextPage,
    disabled: isError,
    rootMargin: "0px 0px 400px 0px",
  });

  const handleSaveHistory = (history: HistoryItem) => {
    setAnalyticActionSource(MixpanelActionSource.Sidebar);

    if (history.type === HistoryType.Search) {
      const searchUrl = getSearchPathFromSearchHistory(history);
      openSaveBookmarkModalForSearch(searchUrl);
    } else if (history.type === HistoryType.Thread) {
      openSaveBookmarkModalForThread(history.thread_id);
    }
  };

  const handleDeleteHistory = async (history: HistoryItem) => {
    if (history.type === HistoryType.Search) {
      return await deleteSearchHistory(history.id);
    } else if (history.type === HistoryType.Thread) {
      // TODO(Jimmy): Migrate this to useThreads hook.
      // Delete the thread and remove it from the client-side thread list or invalidate and refetch the list.
    }
  };

  if (isError || groupedData === undefined) {
    return (
      <div className="flex flex-col items-center gap-2 m-5 mx-8 text-center">
        {searchHistoryLabels["error"]}
        <CoButton
          variant={ButtonVariant.Secondary}
          onClick={() => fetchNextSearchHistoryPage()}
          label={searchHistoryLabels["retry"]}
        />
      </div>
    );
  }

  if (isLoading) {
    return <SidebarContentLoading />;
  }

  return (
    <div
      data-testid="history-container"
      className={classNames(
        "flex-1 pb-[104px] bottom-fade-out max-h-[100%] scrollbar-hide",
        isIncognitoModeActive ? "overflow-hidden" : "overflow-auto"
      )}
    >
      {groupedData.count === 0 ? (
        <div className="flex items-center justify-center w-full h-full pt-4">
          <HistoryEmpty onNavigate={onNavigate} />
        </div>
      ) : (
        <div className="flex flex-col w-full gap-6">
          {groupedData.today.length > 0 && (
            <HistoryGroup
              label={searchHistoryLabels["today"]}
              histories={groupedData.today}
              onNavigate={onNavigate}
              onDelete={handleDeleteHistory}
              onSave={handleSaveHistory}
            />
          )}
          {groupedData.lastSevenDays.length > 0 && (
            <HistoryGroup
              label={searchHistoryLabels["last-seven-days"]}
              histories={groupedData.lastSevenDays}
              onNavigate={onNavigate}
              onDelete={handleDeleteHistory}
              onSave={handleSaveHistory}
            />
          )}
          {groupedData.lastThirtyDays.length > 0 && (
            <HistoryGroup
              label={searchHistoryLabels["last-thirty-days"]}
              histories={groupedData.lastThirtyDays}
              onNavigate={onNavigate}
              onDelete={handleDeleteHistory}
              onSave={handleSaveHistory}
            />
          )}
          {groupedData.lastYear.length > 0 && (
            <HistoryGroup
              label={searchHistoryLabels["last-year"]}
              histories={groupedData.lastYear}
              onNavigate={onNavigate}
              onDelete={handleDeleteHistory}
              onSave={handleSaveHistory}
            />
          )}
        </div>
      )}
      {hasNextPage && (
        <div ref={infiniteRef} className="text-center">
          <SidebarContentLoading />
        </div>
      )}
    </div>
  );
}

export default History;
