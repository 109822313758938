import { SearchHistory, ThreadHistory } from "helpers/api";

export enum HistoryType {
  Search = "search",
  Thread = "thread",
  UploadedPaper = "uploaded_paper",
}

export type SearchHistoryItem = SearchHistory & {
  type: HistoryType.Search;
  link: string;
};
export type UploadedPaperHistoryItem = SearchHistory & {
  type: HistoryType.UploadedPaper;
  link: string;
};
export type ThreadHistoryItem = ThreadHistory & {
  type: HistoryType.Thread;
  link: string;
};

export type HistoryItem =
  | SearchHistoryItem
  | ThreadHistoryItem
  | UploadedPaperHistoryItem;
