import { EXAMPLE_SEARCH_URL_2 } from "constants/config";
import useLabels from "hooks/useLabels";
import Link from "next/link";
import React from "react";

type HistoryEmptyProps = {
  onNavigate: () => void;
};

function HistoryEmpty({ onNavigate }: HistoryEmptyProps) {
  const [searchHistoryLabels] = useLabels("screens.sidebar.history");

  return (
    <div className="mx-4 tracking-wide text-center">
      <i className="text-2xl icon-history text-fg-muted" />
      <h2
        data-testid="title"
        className="w-full my-3 text-center tiny-md text-fg-muted"
      >
        {searchHistoryLabels["title"]}
      </h2>
      <Link
        passHref
        href={EXAMPLE_SEARCH_URL_2}
        className="flex items-center justify-between gap-4 p-3 border border-border-base rounded-xl sm-normal"
        onClick={onNavigate}
      >
        {searchHistoryLabels["query-text"]}
        <i className="icon-search" />
      </Link>
    </div>
  );
}

export default HistoryEmpty;
