import { useAuth, useClerk, useUser } from "@clerk/nextjs";
import { useQueryClient } from "@tanstack/react-query";
import { VenetianMask } from "components/Icon/local";
import Switch from "components/Switch";
import path from "constants/path";
import { apiManagementPageUrl } from "helpers/pageUrl";
import { ISubscriptionType } from "helpers/subscription";
import useCredits from "hooks/useCredits";
import { useIncognitoMode } from "hooks/useIncognitoMode";
import useLabels from "hooks/useLabels";
import Link from "next/link";
import IncognitoModeUserPicture from "./IncognitoModeUserPicture";

export const UserMenuContents = () => {
  const { user } = useUser();
  const { signOut } = useClerk();
  const { isSignedIn } = useAuth();
  const { isIncognitoModeActive, toggleIncognitoMode } = useIncognitoMode();
  const [userPopoverLabels] = useLabels(
    "features.sidebar-navigation.user-popover"
  );

  const { subscriptionType } = useCredits();
  const userCanViewApiLink = subscriptionType === ISubscriptionType.Team;

  const [labels] = useLabels("incognito-mode");

  const queryClient = useQueryClient();

  const handleSignOut = () => {
    // Eliminate all cached API data to avoid leaking between users.
    queryClient.clear();

    signOut({ redirectUrl: path.SIGN_IN });
  };

  return (
    <div className="pb-1 w-60">
      <div className="flex items-center gap-3 p-3 mb-1 border-b border-b-border-base">
        <div className="flex items-center justify-center w-6 h-6 rounded-full">
          {isIncognitoModeActive ? (
            <IncognitoModeUserPicture />
          ) : (
            <img
              src={user?.imageUrl}
              className="w-full h-full rounded-full"
              alt="user profile"
            />
          )}
        </div>
        <div className="flex-1 overflow-hidden">
          <span className="block tiny-md">
            {user?.firstName}&nbsp;{user?.lastName}
          </span>
          <span className="block truncate tiny-normal text-fg-muted">
            {user?.primaryEmailAddress?.emailAddress}
          </span>
        </div>
      </div>
      <div className="flex items-center justify-between p-2 mx-1 rounded-lg hover:bg-bgr-faint tiny-md">
        <div className="flex items-center gap-3 text-fg-base">
          <VenetianMask className="text-xl text-fg-base" />
          <span>{labels["incognito-mode"]}</span>
        </div>
        <Switch
          active={isIncognitoModeActive}
          onChange={() => {
            toggleIncognitoMode();
          }}
        />
      </div>
      <Link
        href="/account"
        className="flex items-center gap-3 p-2 mx-1 rounded-lg hover:bg-bgr-faint tiny-md"
      >
        <i className="text-xl icon-settings" />
        <span>{userPopoverLabels["settings"]}</span>
      </Link>
      <Link
        href="/subscription"
        className="flex items-center gap-3 p-2 mx-1 rounded-lg hover:bg-bgr-faint tiny-md"
      >
        <i className="text-xl icon-credit-card" />
        <span>{userPopoverLabels["subscription"]}</span>
      </Link>

      {isSignedIn && userCanViewApiLink && (
        <Link
          href={apiManagementPageUrl()}
          className="flex items-center gap-3 p-2 mx-1 rounded-lg hover:bg-bgr-faint tiny-md"
        >
          <i className="text-xl icon-code-xml" />
          <span>{userPopoverLabels["api-dashboard"]}</span>
        </Link>
      )}
      <div className="p-2 mx-1 rounded-lg hover:bg-bgr-faint text-red-emphasis">
        <button
          className="flex items-center w-full gap-3 tiny-md"
          onClick={handleSignOut}
        >
          <i className="text-xl icon-arrow-right-from-line" />
          <span>{userPopoverLabels["sign-out"]}</span>
        </button>
      </div>
    </div>
  );
};

export default UserMenuContents;
