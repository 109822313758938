export enum FeatureFlag {
  TEST_FEATURE = "test-feature",
  THREADS = "threads",
  THREADS_DEBUG_TOOLS = "threads-debug-tools",
}

export const FeatureFlagOptions = [
  {
    label: "Threads",
    name: FeatureFlag.THREADS,
  },
  {
    label: "Threads Debug Tools",
    name: FeatureFlag.THREADS_DEBUG_TOOLS,
  },
];

/** Features that are enabled by default. */
export const ForceEnabledFeatures: FeatureFlag[] = [];
