import HotkeyIcon from "components/HotkeyIcon";
import { StackedEmptyModal } from "components/Modal";
import useIsMac from "hooks/useIsMac";
import useLabels from "hooks/useLabels";
import { useModalStack } from "hooks/useModalStack/useModalStack";
import React from "react";
import NewThreadInput from "./NewThreadInput";

// Define a constant for the modal ID
export const NEW_THREAD_MODAL_ID = "new-thread-modal";

function NewThreadModal() {
  const [labels] = useLabels("features.new-thread-button");
  const isMac = useIsMac();
  const { closeModalIfTop } = useModalStack(NEW_THREAD_MODAL_ID);

  // In order to show the hotkey hint at the base of the screen, the modal component is actually discreetly full height of the screen, using a hidden wrapper of the input and hotkey icons.
  // To ensure that clicking on the background still closes the modal, we add a click trigger to this hidden wrapper.
  // This method is to ensure that clicking the input does not trigger the modal to close by propagaging that click event up to that wrapper.
  const handleClickInside = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <StackedEmptyModal modalId={NEW_THREAD_MODAL_ID} alwaysCenter>
      <div
        onClick={() => closeModalIfTop()}
        className="relative flex items-center justify-center sm:h-screen"
      >
        <div
          onClick={handleClickInside}
          className="w-full sm:w-[500px] md:w-[850px] max-h-[95vh] mx-auto px-4"
        >
          <NewThreadInput onClose={closeModalIfTop} />
        </div>
        <div className="absolute flex-col items-center hidden gap-2 text-white sm-md bottom-6 sm:flex">
          <div className="flex gap-1">
            <HotkeyIcon hotkey={isMac ? "⌘" : "Ctrl"} />
            <HotkeyIcon hotkey="K" />
          </div>
          <div className="sm-md">{labels["new-thread"]}</div>
        </div>
      </div>
    </StackedEmptyModal>
  );
}

export default NewThreadModal;
