export const PER_PAGE = 10;

// env
export const WEB_URL = process.env.NEXT_PUBLIC_WEBSITE_URL;
export const IS_PRODUCTION_ENV = process.env.DEPLOYMENT_ENV === "production";
export const MIXPANEL_PROXY_API_HOST = "https://track.consensus.app";

// clerk JWT templates
export const JWT_WITH_EMAIL = "jwt-with-email";
export const JWT_WITH_PREMIUM_STATUS = "jwt-with-premium-status";
export const JWT_WITH_EMAIL_AND_PREMIUM_STATUS =
  "jwt-with-email-and-premium-status";

// email addresses
export const SUPPORT_EMAIL = "support@consensus.app";
export const SALES_EMAIL = "sales@consensus.app";
export const STUDENT_EMAIL = "student@consensus.app";

// social
export const CRUNCHBASE_URL =
  "https://www.crunchbase.com/organization/consensus-c075";
export const INSTAGRAM_URL = "https://www.instagram.com/consensusnlp/";
export const LINKEDIN_URL = "https://www.linkedin.com/company/consensus-nlp/";
export const SLACK_URL =
  "https://join.slack.com/t/consensus-users/shared_invite/zt-2msu2l0dz-DsJaU5R651fcVjzr2_WgrQ";
export const TIKTOK_URL = "https://www.tiktok.com/@consensus_nlp";
export const TWITTER_URL = "https://twitter.com/ConsensusNLP";

// urls
export const API_DOCS_URL = "https://docs.consensus.app";
export const CAREERS_URL = "https://angel.co/l/2ychKe";
export const DOI_URL = "http://dx.doi.org";
export const EXAMPLE_SEARCH_URL =
  WEB_URL + "/results/?q=Does remote patient monitoring improve outcomes?";
export const EXAMPLE_SEARCH_URL_2 =
  WEB_URL + "/results/?q=can magnesium help sleep?";
export const GPT_URL = "https://chat.openai.com/g/g-bo0FiWLY7-consensus";
export const HOME_PAGE_URL = WEB_URL + "/?home=true";
export const INFLUENTIAL_CITATIONS_URL =
  "https://www.semanticscholar.org/faq?utm_source=consensus#influential-citations";
export const OPEN_AI_STATUS_URL = "https://status.openai.com";

// help center urls
export const HELP_CENTER_URL = "https://help.consensus.app";
export const HELP_CENTER_ARTICLE_URL = HELP_CENTER_URL + "/en/articles/";
export const HIERARCHY_OF_EVIDENCE_URL =
  HELP_CENTER_ARTICLE_URL +
  "10262689-the-hierarchy-of-evidence-a-guide-to-understanding-research-quality";
export const RCT_URL =
  HELP_CENTER_ARTICLE_URL + "10236424-randomized-control-trial-rct";
export const SYSTEMATIC_REVIEW_URL =
  HELP_CENTER_ARTICLE_URL + "10207324-systematic-review";
export const META_ANALYSIS_URL =
  HELP_CENTER_ARTICLE_URL + "10151037-meta-analysis";
export const LITERATURE_REVIEW_URL =
  HELP_CENTER_ARTICLE_URL + "10237479-literature-review";
export const OBSERVATIONAL_STUDY_URL =
  HELP_CENTER_ARTICLE_URL + "10237456-observational-study";
export const NON_RCT_URL =
  HELP_CENTER_ARTICLE_URL + "10237297-non-randomized-controlled-trial-non-rct";
export const IN_VITRO_STUDY_URL =
  HELP_CENTER_ARTICLE_URL + "10237493-in-vitro-study";
export const ANIMAL_STUDY_URL =
  HELP_CENTER_ARTICLE_URL + "10237485-animal-study";
export const CASE_REPORT_URL = HELP_CENTER_ARTICLE_URL + "10237480-case-report";
export const CONSENSUS_METER_URL =
  HELP_CENTER_ARTICLE_URL + "10069920-understanding-the-consensus-meter";

// website
export const ABOUT_URL = "https://consensus.app/home/about-us/";
export const FOR_DOCTORS_URL = "https://consensus.app/home/doctors/";
export const FOR_UNIVERSITIES_URL = "https://consensus.app/home/university/";
export const BLOG_URL = WEB_URL + "/home/blog";
export const CONSENSUS_METER_BLOG_URL = BLOG_URL + "/consensus-meter/";
export const COPILOT_BLOG_URL =
  BLOG_URL + "/introducing-the-consensus-co-pilot/";
export const METER_INDICATORS_BLOG_URL = BLOG_URL + "/consensus-meter/";
export const SUMMARY_BLOG_URL =
  BLOG_URL + "/introducing-gpt-4-powered-scientific-summaries/";
export const API_LEARN_MORE_BLOG_URL = WEB_URL + "/home/api/";

// keys
export const GLEAP_API_KEY = process.env.NEXT_PUBLIC_GLEAP_API_KEY;
export const MIXPANEL_API_KEY = process.env.NEXT_PUBLIC_MIXPANEL_API_KEY;
export const META_PIXEL_ID = process.env.NEXT_PUBLIC_META_PIXEL_ID;
export const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
export const INTERCOM_API_BASE = process.env.NEXT_PUBLIC_INTERCOM_API_BASE;
export const INTERCOM_API_IDENTITY_VERIFICATION_SECRET =
  process.env.NEXT_PUBLIC_INTERCOM_API_IDENTITY_VERIFICATION_SECRET;

// meta
export const META_IMAGE = WEB_URL + "/images/consensus-card.png";
export const META_IMAGE_ALT = "An image of the Consensus logo";
export const META_TWITTER_SITE = "@ConsensusNLP";
export const META_RESULTS_IMAGE = WEB_URL + "/images/og-results.png";
export const META_DEFAULT_IMAGE = WEB_URL + "/images/og-default.png";
export const META_SEARCH_IMAGE = META_DEFAULT_IMAGE;
export const META_DETAILS_IMAGE = META_DEFAULT_IMAGE;
export const META_PRICING_IMAGE = META_DEFAULT_IMAGE;
export const META_RESULTS_DYNAMIC_IMAGE = WEB_URL + "/api/og/results";
export const META_THREADS_DYNAMIC_IMAGE = WEB_URL + "/api/og/threads";
export const META_PAPER_DETAILS_DYNAMIC_IMAGE = WEB_URL + "/api/og/papers";
export const META_QUESTIONS_PAGE_DYNAMIC_IMAGE = WEB_URL + "/api/og/questions";

// hubspot
export const HUBSPOT_WEBOOK_URL =
  "https://api-na1.hubapi.com/automation/v4/webhook-triggers/47141966/zpYtdAv";

export const MAX_MOBILE_WIDTH = 800;
export const SEARCH_ITEM = "search";
export const MAX_VISIBLE_AUTHORS = 3;

// Stripe Meta Data
export const STRIPE_PRICE_METADATA_TYPE_PREMIUM = "premium";
export const STRIPE_PRICE_METADATA_TYPE_TEAM = "team";
export const STRIPE_PRICE_METADATA_TYPE_LIFETIME = "lifetime";
export const STRIPE_PRICE_METADATA_TYPE_EARLY_BIRD = "early bird";
export const STRIPE_PRICE_METADATA_TYPE_API = "api";

export const ALLOWED_ORG_DOMAINS_AND_FULL_ORG_NAME: { [key: string]: string } =
  {
    "consensus.app": "Consensus App",
    "virginia.edu": "Univeristy of Virginia",
    "hkbu.edu.hk": "Hong Kong Baptist University",
    "northeastern.edu": "Northeastern University",
    "neu.edu": "Northeastern University",
    "vt.edu": "Virginia Tech",
    "cesa.edu.co": "CESA",
    "gmu.edu": "George Mason University",
    "nas.edu": "National Academies",
    "nae.edu": "National Academies",
    "nbu.bg": "New Bulgarian University",
    "hksyu.edu": "Hong Kong Shue Yan University",
    "hksyu.edu.hk": "Hong Kong Shue Yan University",
    "lmu.edu.ng": "Landmark University",
    "landmarkuniversity.edu.ng": "Landmark University",
  };

export const ALLOWED_API_ORG_IDS = [];

export const CONSENSUS_ADMIN_ORG_SLUG = "consensus-admin";

// Discounts
export const STUDENT_EMAIL_DOMAINS = ["edu", "ac", "student"];
export const STUDENT_DISCOUNT_1_YEAR = "wjkc1OXY";
export const STUDENT_DISCOUNT_2_YEAR = "1GINMYoW";
export const STUDENT_DISCOUNT_3_YEAR = "VwIlgtN0";
export const STUDENT_DISCOUNT_4_YEAR = "gjKtbhze";
export const STUDENT_DISCOUNT_1_YEAR_PERCENT = 40;
export const STUDENT_DISCOUNT_2_YEAR_PERCENT = 40;
export const STUDENT_DISCOUNT_3_YEAR_PERCENT = 40;
export const STUDENT_DISCOUNT_4_YEAR_PERCENT = 40;
export const STUDENT_TRIAL_PERIOD_DAYS = 90;
export const STUDENT_DISCOUNT_PERCENT = 40;

export const ANNUAL_PRICE_DISCOUNT = "33%";
export const ANNUAL_PRICE_DISCOUNT_VALUE = (11.99 - 8.99) / 8.99;

// Mailchimp
export const MAILCHIMP_TAG = "Product_Activation";

// Bookmark
export const BOOKMARK_LISTNAME_FAVORITE = "My favorites";
export const BOOKMARK_LISTNAME_MAX_LENGTH = 100;
export const BOOKMARK_MAX_CUSTOM_LIST_NUM = 1;
export const BOOKMARK_MAX_ITEM_NUM = 10;

export const PRIVACY_PAGE_URL = WEB_URL + "/home/privacy-policy/";
export const TERMS_PAGE_URL = BLOG_URL + "/terms-of-service/";
export const EXAMPLE_QUERY = "Does beta alanine improve exercise performance?";

// Number of ms for how long it takes to display the confirmation message after click copy.
export const COPY_TIMEOUT_MILLISECONDS = 3000;

// Credit usage
export const MAX_CREDIT_NUM = 20;
export const CREDIT_RESET_INTERVAL_DAYS = 30;
export const MAX_CREDIT_NUM_PREMIUM = 200;
export const MIN_TEAM_SEATS_COUNT = 1;
export const MAX_TEAM_SEATS_COUNT = 200;
export const PRO_ANALYSIS_CREDITS_PER_MONTH = 10;
export const SNAPSHOT_CREDITS_PER_MONTH = 10; // Study Snapshot and Consensus Snapshot credits
export const ASK_PAPER_CREDITS_PER_MONTH = 10;

// Server side only
export const META_ACCESS_TOKEN = process.env.META_ACCESS_TOKEN;
export const NEXTJS_LOG_LEVEL = process.env.NEXTJS_LOG_LEVEL;

// Languages
export const DEFAULT_LANG = "en";
