import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { updateIsIncognitoModeActive } from "store/slices/user";

export const useIncognitoMode = () => {
  const isIncognitoModeActive = useAppSelector(
    (state) => state.user.isIncognitoModeActive
  );
  const dispatch = useAppDispatch();

  const toggleIncognitoMode = async () => {
    const newIncognitoMode = !isIncognitoModeActive;
    dispatch(updateIsIncognitoModeActive(newIncognitoMode));
  };

  return {
    isIncognitoModeActive,
    toggleIncognitoMode,
  };
};
