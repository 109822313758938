import { useAuth } from "@clerk/nextjs";
import path from "constants/path";
import { useRouter } from "next/router";

/**
 * A hook that ensures the user is signed in before continuing.
 */
export function useRequireSignIn() {
  const router = useRouter();
  const { isSignedIn, isLoaded } = useAuth();

  // returns isSignedIn, aka "can continue"
  // if auth is not loaded yet, it will return false and also not redirect.
  const redirectIfNotSignedIn = (redirectPath?: string): boolean => {
    if (!isLoaded) return false;

    if (!isSignedIn) {
      const finalPath = redirectPath ?? router.asPath;
      const redirect = `/?redirect_url=${encodeURIComponent(finalPath)}`;
      router.push(`${path.SIGN_IN}${redirect}`);
      return false;
    }

    return true;
  };

  return { redirectIfNotSignedIn };
}
